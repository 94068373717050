import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import '../css/service.css'
// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import {Card, CardHeader, CardFooter, Image, Avatar} from "@nextui-org/react";

import logo3design from '../img/3D_BLANCO_LOGO.png';


const arrayData =[{
    path:"/assets/img/services/DISENO_ARQUITECTONICO.jpg",
    titulo:"Diseño Arquitectónico"
},
{
    path:"/assets/img/services/PLANOS_ARQUITECTONICOS.jpg",
    titulo:"Planos Arquitectónicos"
},
{
    path:"/assets/img/services/MODELADO_3D.jpg",
    titulo:"Modelo 3D"
},
{
    path:"/assets/img/services/RENDERIZADO.jpg",
    titulo:"Renderizado"
},
{
    path:"/assets/img/services/CALCULO_ESTRUCTURAL.jpg",
    titulo:"Cálculo Estructural"
},
{
    path:"/assets/img/services/INGENIERIA_BASICA.jpg",
    titulo:"Ingeniería Básica"
},
{
    path:"/assets/img/services/INGENIERIA_DETALLE.jpg",
    titulo:"Ingeniería a Detalle"
},
{
    path:"/assets/img/services/MODELADO_ESTRUCTURAL.jpg",
    titulo:"Modelado Estructural"
}];


const Service=() =>{
    return (
        <div class="fondo" className="w-full h-full" >
            <h1 className="p-5 text-5xl">SERVICIOS</h1>
            <div class="divuno">
        <>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          loop={true}
          autoplay={{
            delay:5000,
            disableOnInteraction:false
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: -100,
            depth: 500,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
            {arrayData.map(x=>(
          <SwiperSlide>
          <Card isFooterBlurred className="w-full h-full" shadow="sm">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                <Avatar className="animate-pulse" isBordered radius="full" size="md" src={logo3design} />
                </CardHeader>
                <Image
                isZoomed
                    removeWrapper
                    alt="Relaxing app background"
                    className="z-0 w-full h-full object-cover "
                    src= {x.path}
                />
                <CardFooter className="h-[100px] absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                    <div className="flex   content-center">
                   
                    <div className="flex flex-col">
                        <h2  className="text-tiny text-white text-xl">{x.titulo}</h2>
                    </div>
                    </div>
                </CardFooter>
            </Card>
          </SwiperSlide>
          ))}
        </Swiper>
      </>
      </div>
      </div>
    )
}

export default Service;