import React from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem} from "@nextui-org/react";

import { Link } from "react-router-dom";

import { AiFillFacebook } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";

import {Image} from "@nextui-org/image";

import logo3design from '../img/3D_BLANCO_LOGO.png';


const links =[{
    name:"Acerca de Nosotros",
    href:"/About"
},{
    name:"Arquitectura",
    href:"/Architecture"
},{
    name:"Ingeniería",
    href:"/Ingeniery"
},{
    name:"Proyectos",
    href:"/Proyect"
},{
    name:"Servicios",
    href:"/Service"
},{
    name:"Contacto",
    href:"/Contact"
}];

export default function MyHeader() {
    const [isMenuOpen, setIsMenuOpen] = React.useReducer((current) => !current, false);


  return (
   
    <Navbar className="myheader"
    isBordered
    isMenuOpen={isMenuOpen}
    onMenuOpenChange={setIsMenuOpen}
    position="static"
  >
    <NavbarContent className="sm:hidden" justify="start">
      <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
    </NavbarContent>

    <NavbarContent className="sm:visible md:visible pr-10" justify="left">
        <NavbarBrand>
          <Link color="foreground" to="/home">
            <Image isZoomed   isBlurred             
                width={50}
                alt="NextUI hero Image"
                src={logo3design}
                />       
        </Link>
        </NavbarBrand>
      </NavbarContent>
        
      
      <NavbarContent className="hidden sm:flex gap-4 pl-10" justify="center">
        {links.map(x=>(
            <NavbarItem >
                <Link  to={x.href} aria-current="page" color="secondary" >
                    {x.name}
                </Link>
            </NavbarItem>
        ))}
      </NavbarContent>

    <NavbarContent justify="end">
    <NavbarItem>
            <Link color="foreground" to="https://www.facebook.com/profile.php?id=100076969625744&mibextid=ZbWKwL"><AiFillFacebook  /></Link>
        </NavbarItem>
        <NavbarItem>
            <Link color="foreground" to="https://www.instagram.com/3design.arquitectos_/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"><FaInstagram /></Link>
        </NavbarItem>
        <NavbarItem>
            <Link color="foreground" to="https://www.tiktok.com/@3design.arq?_t=8r6Sk6vuQT8&_r=1"><AiFillTikTok /></Link>
        </NavbarItem>
    </NavbarContent>

    <NavbarMenu className="myheader z-50"> 
      {links.map((item, index) => (
        <NavbarMenuItem key={`${item}-${index}`} className="z-50">
          <Link
            className="w-full"
            color={
              index === 2 ? "warning" : index === links.length - 1 ? "danger" : "foreground"
            }
            to={item.href}
            size="lg"
            onPress={() => {setIsMenuOpen();}}
            onClick={() => {
                setIsMenuOpen(!isMenuOpen);}}
          >
            {item.name}
          </Link>
        </NavbarMenuItem>
      ))}
    </NavbarMenu>
  </Navbar>

    
  );
}
