import React from "react";
import { Link } from "react-router-dom";
import {Divider, Spacer,Card, CardBody, Image} from "@nextui-org/react";
import '../css/proyect.css'
const arrayData =[
    
{
    path:'/assets/img/proyects/structure/NAVE-ZWK.jpg',
    title:'NAVE ZWK',
    subtitle:'Estructura Metálica principal',
    items:['Vigas principales a base de IPR',
            'Vigas secundarias a base de IPR',
            'Columnas a base de IPR',
            'Planos de fabricación',
            'Reportes, generadores'],
    weight:'1170.00 TON'
},
{
    path:'/assets/img/proyects/structure/SUPER-ESTRUCTURA-TERNIUN.JPG',
    title:'SUPER ESTRUCTURA TERNIUN',
    subtitle:'Estructura Metálica principal',
    items:['Vigas principales a base de IPR',
            'Vigas secundarias a base de IPR',
            'Columnas a base de IPR',
            'Conexiones',
            'Planos de fabricación',
            'Informes, generadores'],
    weight:'1060.00 TON'
},
{
    path:'/assets/img/proyects/structure/IOMEX.jpg',
    title:'IOMEX',
    subtitle:'Estructura Metálica principal',
    items:['Marcos a basé de cartelas',
            'Columnas a base de IPR',
            'Contravientos OS',
            'Planos de fabricación',
            'Informes, generadores'],
    weight:'280.00 TON'
},
{
    path:'/assets/img/proyects/structure/HOTEL-VALLARTA.jpg',
    title:'HOTEL VALLARTA',
    subtitle:'Estructura Metálica principal',
    items:['Vigas principales a base de IPR',
            'Vigas secundarias a base de IPR',
            'Columnas a base de 4 placas',
            'Contravientos HSS',
            'Planos de fabricación',
            'Reportes, generadores'],
    weight:'260.00 TON'
},

{
    path:'/assets/img/proyects/structure/ELEKTRA.jpg',
    title:'ELEKTRA',
    subtitle:'Estructura Metálica principal',
    items:['Columnas a base de IPR',
        'Vigas principales a base de IPR',
        'Vigas secundarias a base de IPR',
            'Conexiones',
            'Dibujos de fabricación',
            'Reportes, generadores'],
    weight:'170.00 TON'
},
{
    path:'/assets/img/proyects/structure/MEZZANINE-HOSPITAL.jpg',
    title:'MEZZANINE PARA HOSPITAL',
    subtitle:'Estructura Metálica principal',
    items:['Vigas principales a base de IPR',
            'Vigas secundarias a base de IPR',
            'Columnas a base de 4 placas',
            'Contravientos HSS',
            'Planos de fabricación',
            'Reportes, generadores'],
    weight:'104.00 TON'
},
{
    path:'/assets/img/proyects/structure/HOSPITAL.jpg',
    title:'HOSPITAL',
    subtitle:'Estructura Metálica principal',
    items:['Vigas principales a base de IPR',
            'Vigas secundarias a base de IPR',
            'Columnas a base de IPR',
            'Planos de fabricación',
            'Reportes, generadores'],
    weight:'95.00 TON'
},
{
    path:'/assets/img/proyects/structure/COLEGIO.jpg',
    title:'COLEGIO',
    subtitle:'Estructura Metálica principal',
    items:['Columnas a base de IPR',
            'Vigas principales a base de IPR',
            'Contravientos a base de OS',
            'Fachadas a bse de monten CF',
            'Cubierta a base de monten CF'],
    weight:'90.00 TON'
},
{
    path:'/assets/img/proyects/structure/MEZZANINE-ARMADURAS.jpg',
    title:'MEZZANINE ARMADURAS',
    subtitle:'Estructura Metálica principal',
    items:['Columnas a base de IPR',
            'Marcos principales a base de IPR',
            'Vigas secundarias a base de IPR',
            'Conexiones',
            'Dibujos de fabricación',
            'Reportes, generadores'],
    weight:'80.00 TON'
},
{
    path:'/assets/img/proyects/structure/PUENTE-PEATONAL.jpg',
    title:'PUENTE PEATONAL',
    subtitle:'Estructura Metálica principal',
    items:['Vigas principales a base de IPR',
            'Vigas secundarias a base de IPR',
            'Columnas a base de IPR',
            'Contravientos HSS',
            'Planos de fabricación',
            'Reportes, generadores'],
    weight:'60.00 TON'
},
{
    path:'/assets/img/proyects/structure/COMEDOR.jpg',
    title:'COMEDOR',
    subtitle:'Estructura Metálica principal',
    items:['Marcos rígidos de IPR',
            'Columnas a base de IPR',
            'Vigas secundarias a base de IPR',
            'Vigas IPR roladas',
            'Conexiones',
            'Dibujos de fabricación'],
    weight:'45.00 TON'
},
{
    path:'/assets/img/proyects/structure/CUBIERTA-AGUASCALIENTES.jpg',
    title:'CUBIERTA AGUASCALIENTES',
    subtitle:'Estructura Metálica principal',
    items:['Armaduras a base de Ángulos',
            'Columnas a base de IPR',
            'Fachadas a bse de monten CF',
            'Cubierta a base de monten CF',
            'Planos de fabricación'],
    weight:'38.00 TON'
},
{
    path:'/assets/img/proyects/structure/BODEGA-RANCHO35.jpg',
    title:'BODEGA RANCHO',
    subtitle:'Estructura Metálica principal',
    items:['Armadura a base de Ángulos',
            'Columnas a base de IPR',
            'Contravientos a base de IPR',
            'Contravientos a base de OS',
            'Fachadas a base de monten CF',
            'Cubierta a base de monten'],
    weight:'35.00 TON'
},
{
    path:'/assets/img/proyects/structure/CABANA-SANMIGUEL.jpg',
    title:'CABAÑA SAN MIGUEL',
    subtitle:'Estructura Metálica principal',
    items:['Columnas a base de IPR',
            'Vigas principales a base de IPR',
            'Escalones a base de CE',
            'Visualización a base de CE',
            'Visualización de obra civil'],
    weight:'6.00 TON'
}
];

const links =[{
    name:"ESTRUCTURA",
    href:"/proyectS"
},{
    name:"ARQUITECTURA",
    href:"/proyectA"
}];

const ProyectStructure=() =>{
    return (<div ClassName="w-full "> 
        <h1 className="p-5 text-5xl">PROYECTOS</h1>
      <div className=" flex justify-center ">
          {links.map(x=>(
                  <Link className="p-5" to={x.href} aria-current="page" color="secondary" >
                      <label className="transparent-backgroundLabel">{x.name}</label>  
                  </Link> 
      ))}
      <Spacer x={4}/>
      </div>
      <Divider className="my-4" />
    
      
      <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4 ml-6 mr-6  flex justify-center">
      {arrayData.map(x=>(
      <Card
            isBlurred
            className="transparent-backgroundLabel"
          > 
            <CardBody className="">
              <div className="grid grid-cols-12 md:grid-cols-12 gap-1 md:gap-1 flex justify-center">
                <div className="flex justify-center col-span-12 md:col-span-12">
                <Image
                    alt="Album cover"
                    className="object-cover"
                    shadow="md"
                    src={x.path}
                    />
                </div>
      
                <div className="flex justify-center flex-col col-span-12 md:col-span-12">
                  <div className="flex justify-between  items-start">
                    <div className="flex flex-col justify-center col-span-12 gap-0">
                    <h1 className=" text-large font-medium mt-2 ">{x.title} - {x.weight} </h1>
                      <h3 className="font-semibold text-white ">{x.subtitle}  </h3>
                    </div>
                   
                  </div>
                   
                  {x.items.length>0 ? (
                      <div className="w-full    rounded-small">
                 
                      {x.items.map((texto)=>
                       <div className="flex  ">
                       <li>{texto}</li>
                          
                     </div>
                     )}
                         </div>
                  ) : (
                     <div></div>
                  )} 
                  
                </div>
              </div>
            </CardBody>
          </Card>
      ))}
      </div>
      
          </div>)
}

export default ProyectStructure;