import React from "react";

import banner1 from '../img/aboutus/banner1.jpeg'; //'/img/aboutus/banner1.PNG';
import banner2 from '../img/aboutus/banner2.jpeg';
import banner3 from '../img/aboutus/banner3.jpeg';
import banner4 from '../img/aboutus/banner4.jpeg';

import '../css/gallery.css'
const Gallery=() =>{
    return <div class="w-full px-8">
        <h1 className="p-5 text-5xl uppercase">Galeria</h1>

        
        <div class="rounded-md dark:bg-default-100/50  text-slate-700  sm:px-0 md:px-8" shadow="sm">
  <div class="float-right mr-6 px-4">
    
    <div class="relative aspect-w-4 aspect-h-5 sm:aspect-w-16 sm:aspect-h-9 w-20 sm:w-44">
      
      <div class="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-lg"></div>
    </div>
   
  </div>
  <p class=" text-justify text-slate-300 md:text-4xl sm:text-sm  ">3Design somos un despacho de Arquitectos e Ingenieros dedicados a ofrecer servicios en el área Estructural y Arquitectónica con la finalidad de llevar a cabo las necesidades que se les presentes a nuestros clientes.</p>
<p class=" text-justify text-slate-300 md:text-4xl sm:text-sm">Somos un equipo proactivo, capaz de realizar toda aquella actividad que se nos solicite de manera responsable y comprometida.</p>
</div>


  <div class="inline-grid grid-cols-4 py-5" > <img class=""  
      isZoomed
      width={300}
      height={300}
      alt="img"
      src={banner1}/>
       <img class=""  
      isZoomed
      width={300}
      height={300}
      alt="img"
      src={banner2}/>
       <img class=""  
      isZoomed
      width={300}
      height={300}
      alt="img"
      src={banner3}/>
       <img class=""  
      isZoomed
      width={300}
      height={300}
      alt="img"
      src={banner4}/></div>
 
 <div class="w-full grid inline-grid grid-cols-3   px-0 ">
  <span  className="transparent-backgroundLabel  sm:text-xs px-1 " alt="DISEÑAMOS"   >
        DISEÑAMOS
      </span>  
      <span className="transparent-backgroundLabel  sm:text-xs mx-1" alt="CREAMOS">
        CREAMOS
      </span>  
      <span className="transparent-backgroundLabel  sm:text-xs  truncate " alt="CONSTRUIMOS">
        CONSTRUIMOS
      </span>  
</div>
    </div>
    
}

export default Gallery;